import React from 'react'
import Container from '../components/ui/Container'
import ItalicHeading from '../components/ui/ItalicHeading'
import PolicyText from '../components/ui/PolicyText'
import Layout from '../components/Layout'
import styled from 'styled-components'
import SEO from '../components/seo'
import { toPage } from '../lib/utils'
import { graphql } from 'gatsby'

const Wrapper = styled.div`
    padding: 40px 0;
`
export default function CancelationPolicyPage({ data }) {

  const page = toPage(data)

  //TODO: prismic does not let you have realtive links in their Rich Text Fields 
  const contents = page.contents.replace(/https:\/\/\//g, "/")

  return <Layout>
    <SEO title="Cancelation Policy" />
    <Wrapper>
      <Container>
        <ItalicHeading>{page.title}</ItalicHeading>
        <PolicyText dangerouslySetInnerHTML={{ __html: contents }} />
      </Container>
    </Wrapper>
  </Layout>
}

export const query = graphql`
  query CancellationPolicyQuery {
    page: allPrismicCancellationpolicy {
    edges {
      node {
        data {
          contents {
            html
          }
          title {
            text
          }
        }
      }
    }
  }
}
`